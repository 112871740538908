.form-control-group {
    position: relative;
    margin-bottom: 2rem;
}

.form-control-group label {
    display: block;
    margin-bottom: .8rem;
    font-size: 1.4rem;
    line-height: 2rem;
    opacity: .5;
}

.form-control-group input,
.form-control-group textarea,
.form-control-group select {
    height: 4rem;
    width: 100%;
    padding: .8rem;
    font-size: 1.6rem;
    color: #4E4941;
    border: 1px solid #4D4840;
    border-radius: .4rem;
}

.form-control-group textarea {
    height: 7.2rem;
}

.form-control-group input:focus,
.form-control-group textarea:focus,
.form-control-group select:focus {
    outline: 0;
    border-color: #88D1DF;
}

.form-control-group.with-icon .icon {
    position: absolute;
    right: .8rem;
    top: 3.6rem;
}

.form-control-group.with-icon input {
    padding-right: 3.4rem;
}

.form-control-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M17.925 9l-5.963 5.963L6 9" stroke-width="2" stroke="#4D4840"/></svg>') no-repeat right .5rem center;
    background-size: 2.4rem 2.4rem;
    padding-right: 3.5rem;
}

.form-control-group.with-icon select {
    padding-right: 5.5rem;
    background-position: right 3rem center
}
