.multi-select-values {
    border: 1px solid #4D4840;
    padding: .9rem .9rem .5rem;
    cursor: text;
    border-radius: .5rem;
}

.multi-select-value {
    float: left;
    border: 1px solid #4D4840;
    padding: .1rem .8rem .1rem 1rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    border-radius: 1rem;
    cursor: pointer;
    margin-right: 1rem;
    margin-bottom: .4rem;
}

.multi-select-value span {
    vertical-align: top;
}

.multi-select-values input {
    float: left;
    width: 15px;
    height: 2rem;
    font-size: 1.6rem;
    line-height: 1.25;
    padding: 0;
    border: none;
    margin-bottom: .4rem;
    max-width: 100%;
}

.multi-select-values input::placeholder {
    color: #BFB9B2;
}

.multi-select-values .icon-progress {
    position: absolute;
    right: 1rem;
    top: .7rem;
}

.multi-select-value-remove {
    display: inline-block;
    margin-left: .5rem;
    margin-top: .1rem;
    line-height: 1.2rem;
    font-size: 1.8rem;
}

.multi-select-measure {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    white-space: pre;
    height: 0px;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.25;
    min-width: 15px;
    padding-right: 5px;
}

.multi-select-results {
    z-index: 999999;
    position: absolute;
    top: calc(100% - .7rem);
    left: 0;
    right: 0;
    border: 1px solid #4D4840;
    border-top-width: 0;
    background: #fff;
    border-radius: 0 0 .5rem .5rem;
    padding-top: .5rem;
}

.multi-select-result {
    cursor: pointer;
    border-top: 1px solid #D3D3D3;
    padding: .5rem 1rem;
    font-size: 1.6rem;
    line-height: 2rem;
}

.multi-select-result:hover {
    background: #acc32b;
    color: #fff;
}
