.btn {
    display: inline-block;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #4D4840;
    color: #4E4941;
    background: #fff;
    padding: .6rem 1.2rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    border-radius: .5rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,0,0,.05);
}

.btn:disabled {
    background: #E9E7E4;
    color: #fff;
    border-color: #E9E7E4;
}

.btn-block {
    width: 100%;
}

.btn-primary {
    background: #FFA900;
    border-color: #FFA900;
    color: #fff;
}

.btn-secondary {
    border-color: #749B3A;
    color: #749B3A;
}

.btn .icon + span {
    margin-left: 1rem;
}

.btn-small {
    line-height: 1.4rem;
}

.btn-large {
    line-height: 3.2rem;
}
