.tag-select {
    border: 1px solid #9A9389;
    padding: .5rem 1.5rem 1.5rem;
    border-radius: 5px;
    position: relative;
}

.tag-select span {
    margin: 0;
}

.tag-select input {
    color: #7A7366;
    border: 0;
    padding: 0;
    margin: 1rem 0 0;
    height: 2rem;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.25;
    width: 100%;
}

.tag-select input:focus {
    border: 0;
    outline: none;
}

.tag-select-measure {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    white-space: pre;
    height: 0px;
    font-family: Lato;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.25;
}

.tag-select-search {
    display: inline-block;
    position: relative;
    min-width: 100px;
    max-width: calc(100% - 7rem);
}

.tag-select-placeholder {
    position: absolute;
    top: 1rem;
    left: 0;
    color: #BFB9B2;
    font-family: Lato;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.25;
    white-space: nowrap;
}

.tag-select-tag {
    position: relative;
    display: inline-block;
	border: 1px solid #7A7366;
    line-height: 2rem;
    padding: 0 2.2rem 0 .8rem;
    margin-top: 1rem;
    margin-right: 1rem;
    border-radius: 1rem;
}

.tag-select-tag-remove {
    position: absolute;
    right: .8rem;
    top: calc(50% - .9rem);
    font-size: 2rem;
    line-height: 1.6rem;
    vertical-align: text-top;
    padding-left: .5rem;
    cursor: pointer;
}

.tag-select-results {
    position: absolute;
    left: -1px;
    right: -1px;
    top: calc(100% - 1rem);
    border: 1px solid #9A9389;
    background: #fff;
    z-index: 40;
    max-height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.tag-select-result {
    padding: .7rem 1.5rem;
    color: #7A7366;
    font-family: Lato;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.25;
    cursor: pointer;
}

.tag-select-result.selected {
    background: #acc32b;
    color: #fff;
}
