.checkbox {
    position: relative;
}

.checkbox input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.checkbox input:focus + label {
    outline: 1px dotted #D3D3D3;
}

.checkbox label {
    padding: .3rem 0;
    margin: 0;
    opacity: 1;
}

.checkbox span {
    margin-left: .8rem;
}
