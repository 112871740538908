.alert {
	padding: 2rem;
	border: 1px solid #87D1DF;
	border-radius: 5px;
	color: #87D1DF;
	background: #fff;
	margin-bottom: 2rem;
}

.alert .icon {
	margin-right: 2rem;
}

.alert-danger {
	border-color: #D4492E;
	color: #D4492E;
}
