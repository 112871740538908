.login-form-feature {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
    text-align: center;
    max-width: 31rem;
    margin: 0 auto;
    color: #7a7366;
}

.login-form-feature h4 {
    font-size: 2.4rem;
    line-height: 1.35;
    font-weight: 300;
    margin-bottom: 2rem;
}

.login-form-feature .social-buttons a {
    display: block;
    text-align: left;
    height: 5rem;
    font-size: 1.4rem;
    line-height: 3.4rem;
    font-weight: 300;
    padding: 6px;
    margin: 5px 5px 0;
    border-radius: 4px;
}

.login-form-feature .social-buttons svg {
    margin: 0 1.5rem;
    vertical-align: middle;
}

.login-form-feature .social-buttons .facebook {
    color: #fff;
    background-color: #3b5998;
    border: 1px solid #f6f6f6;
}

.login-form-feature .social-buttons .facebook path {
    fill: #fff;
}

.login-form-feature .social-buttons .google {
    background-color: #fff;
    border: 1px solid #f6f6f6;
    color: #7a7366;
}

.login-form-feature .with-email {
    padding: 1.5rem 0;
}

.login-form-feature .input-container {
    position: relative;
}

.login-form-feature .input-container .icon {
    position: absolute;
    left: 18px;
    top: 18px;
}

.login-form-feature .input-container input {
    display: block;
    width: 100%;
    height: 60px;
    font-size: 14px;
    font-weight: 300;
    background-color: #fff;
    border: 1px solid #f6f6f6;
    margin: 0;
    padding-left: 55px;
}

.login-form-feature button {
    display: block;
    height: 5rem;
    width: 100%;
    margin-top: 1rem;
    font-weight: 300;
    background-color: #acc32b;
    border: 1px solid #acc32b;
    font-size: 1.4rem;
    color: #fff;
    border-radius: .4rem;
}

.login-form-feature .login-error-container {
    background-color: #d64525;
    color: #fff;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.6rem;
    min-height: 3.2rem;
    border-radius: .5rem;
    text-align: left;
    padding: 1rem;
}

.login-form-feature .login-error-container a {
    color: #fff;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-decoration: none;
}

@media (min-width: 550px) {
    .login-form-feature h4 {
        font-size: 3rem;
    }
}