.icon {
	display: inline-block;
	height: 2.4rem;
	width: 2.4rem;
	vertical-align: middle;
}

.icon + span {
	vertical-align: middle;
}
